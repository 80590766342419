import adminConstant from './constants';

const initialState = {
    inProgress: false, failed: false, logs: [], receptStat: [], users: [],errorMessage: []
};

const admin = (state = { ...initialState }, action) => {
    switch (action.type) {
        case adminConstant.SEARCH_START:
            return {
                ...state,
                ...initialState,
            };
        case adminConstant.GET_LOGS_INIT:
        case adminConstant.GET_RECEPT_STAT_INIT:
        case adminConstant.GET_USERS_INIT:
            return {
                    ...state,
                    inProgress: true,
                };
        case adminConstant.GET_LOGS_DONE:
            return {
                ...state,
                inProgress: false,
                logs: action.logs
            };
        case adminConstant.GET_RECEPT_STAT_DONE:
            return {
                ...state,
                inProgress: false,
                receptStat: action.stat
            };
        case adminConstant.GET_USERS_DONE:
            return {
                ...state,
                inProgress: false,
                users: action.users
            };
        case adminConstant.GET_LOGS_FAIL:
        case adminConstant.GET_RECEPT_STAT_FAIL:
        case adminConstant.GET_USERS_FAIL:
            return {
                ...state,
                inProgress: false,
                logs:[],
                failed: true,
                errorMessage: action.errorMessage,
            };
        default:
            return state;
    }

}

export default admin;