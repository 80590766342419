import { combineReducers } from 'redux';
//import { routerReducer } from 'react-router-redux';
import login from './containers/SignIn/reducer';
import api from './common/reducers/api';
import notification from './common/reducers/notification';
import get from './common/reducers/get';
import addEdit from './containers/AddRecept/reducer';
import search from './containers/Search/reducer';
import user from './containers/User/reducer';
import admin from './containers/Admin/reducer';



export default combineReducers({
   // routing: routerReducer,
    login,
    api,
    notification,
    addEdit,
    search,
    get,
    user,
    admin,

})