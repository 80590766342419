import constants from '../common/constants';
import loginConstants from '../containers/SignIn/constants';
import notificationConstants from '../common/actions/notification_action';

const handleApiAction = (promise, success, init = undefined) => {
  const apiRequestInit = () => ({ type: constants.API_REQUEST });
  const apiRequestFailure = error => ({
    type: constants.API_REQUEST_FAILURE,
    ...success,
    error,
  });
  const apiUnauthorized = () => ({ type: loginConstants.AUTH_FAILED });
  return dispatch => {
    if (init) {
      dispatch(init());
    } else {
      dispatch(apiRequestInit());
    }
    return promise
      .then(response => {
        if (response.status === 401) {
          dispatch(notificationConstants.errorNotification('401: Unauthorized User. Token might be expired, please re-login'));
          dispatch(apiUnauthorized());
        } else {
          dispatch(success(response, dispatch));
        }
      })
      .catch(error => {
        dispatch(apiRequestFailure(error));
      });
  };
};

export default handleApiAction;
