import constants from '../constants';

const clearNotifcation = type => {
  if (type === 'success') {
    return { type: constants.CLEAR_SUCCESS };
  } if (type === 'error') {
    return { type: constants.CLEAR_ERROR };
  } if (type === 'info') {
    return { type: constants.CLEAR_INFO };
  } if (type === 'warning') {
    return { type: constants.CLEAR_WARNING };
  }
  return {};
};

const infoNotification = message => ({
  type: constants.INFO_NOTIFICATION,
  payload: { message },
});
const successNotification = message => ({
  type: constants.SUCCESS_NOTIFICATION,
  payload: { message },
});

const warningNotification = message => ({
  type: constants.WARNING_NOTIFICATION,
  payload: { message },
});

const errorNotification = message => ({
  type: constants.ERROR_NOTIFICATION,
  payload: { message },
});

const notificationAction = {
  infoNotification,
  clearNotifcation,
  successNotification,
  warningNotification,
  errorNotification,
};

export default notificationAction;
