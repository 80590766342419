import addConstant from './constants';

const initialState = {
    inProgress: false, failed: false, receptId: 0, errorMessage: '', editOk: false, deleteOk: false, deleteInprogress: false
};
const initialData = {
    tagsData: [], ingredientData: [], dataFetched: false, unitsData: [], editRecept: {}, receptFetched: false,
};

const add = (state = { ...initialState, ...initialData }, action) => {
    switch (action.type) {
        case addConstant.SEARCH_START:
            return {
                ...state,
                ...initialState,
            };
        case addConstant.ADD_RECEPT_REQUEST:
            return {
                ...state,
                ...initialState,
                inProgress: true,
            };
        case addConstant.ADD_RECEPT_SUCCESS:
            return {
                ...state,
                ...initialState,
                receptId: action.receptId
            };
        case addConstant.ADD_RECEPT_FAILURE:
        case addConstant.LOAD_ADD_FAIL:
        case addConstant.GET_RECEPT_FAIL:
        case addConstant.DELETE_RECEPT_FAILURE:
            return {
                ...state,
                ...initialState,
                failed: true,
                errorMessage: action.errorMessage,
            };
        case addConstant.LOAD_ADD_INIT:
            return {
                ...initialData,
                ...initialState,
            };
        case addConstant.LOAD_ADD_UNIT:
            return {
                ...state,
                ...initialState,
                unitsData: action.unitsData,
            };
        case addConstant.LOAD_ADD_TAG:
            return {
                ...state,
                ...initialState,
                tagsData: action.tagsData,
            };
        case addConstant.LOAD_ADD_INGREDIENT:
            return {
                ...state,
                ...initialState,
                ingredientData: action.ingredientData,
            };
        case addConstant.LOAD_ADD_DONE:
            return {
                ...state,
                ...initialState,
                dataFetched: true,
            };
        case addConstant.GET_RECEPT_INIT:
            return {
                ...state,
                ...initialState,
                editRecept: state.recept,
                receptFetched: state.receptFetched,
                editOk: false,
            };
        case addConstant.GET_RECEPT_DONE:
            return {
                ...state,
                editRecept: action.recept,
                receptFetched: true,
            };
        case addConstant.EDIT_RECEPT_DONE:
            return {
                ...state,
                editRecept: {},
                receptFetched: false,
                editOk: true,
            };
        case addConstant.DELETE_RECEPT_DONE:
            return {
                ...state,
                deleteOk: true,
                deleteInprogress: false,
            };
            case addConstant.DELETE_RECEPT_REQUEST:
                return {
                    ...state,
                    deleteInprogress: true,
                };
        default:
            return state;
    }

}

export default add;