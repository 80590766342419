import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const RequireAuthentication = ({ component: Component, ...props }) => (
  <div>
    {props.isAuthenticated === true ? (
      <Route {...props} component={Component} />
    ) : (
      <Redirect to={{
        pathname: '/signin',
        search: `?next=${props.location.pathname}`,
        state: { from: props.location },
      }}
      />
    )}
  </div>
);

const mapStateToProps = state => ({
  token: state.login.token,
  isAuthenticated: state.login.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(RequireAuthentication));
