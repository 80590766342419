import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserModal from './UserModal';




class RegisterUser extends Component {
    
    
    render() {
        return (
                <UserModal onCancel={this.props.onCancel}
                    onCreate={this.props.onCreate}
                    visible={this.props.visible}
                    loading={this.props.loading} 
                    update={false}
                    />
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});


export default connect(
    mapStateToProps,
    null,
)(RegisterUser);