import loginConstants from './constants';
import api from '../../api/api';
import handleApiAction from '../../api/utils';
import constants from '../../common/constants';
import notificationAction from '../../common/actions/notification_action';



const onLogin = (usr, pass, redirect, props) => {
    const hosts = [];
    const loginSuccess = (user, userId, token) => ({
        type: loginConstants.LOGIN_SUCCESS,
        user,
        userId,
        token,
    });

    const promise = api.login(usr, pass, hosts);
    const apiInit = userName => ({ type: loginConstants.LOGIN_REQUEST, userName });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 200) {
            dispatch({ type: loginConstants.LOGIN_FAILURE})
            return (notificationAction.errorNotification(response.data.ErrorMessage));
        }
        dispatch(loginSuccess(response.data.user, response.data.userId, response.data.token));
        //dispatch(push(props.history.push(redirect)));
        return { type: loginConstants.LOGIN_DONE };
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const onForgotPassword = (value) => {
    const promise = api.protectedFetch(`${constants.ROOT_URL}user/forgot`, [], 'POST', {}, value)
    const apiInit = () => ({ type: loginConstants.FORGOT_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 200) {
            dispatch({ type: loginConstants.FORGOT_FAILURE})
            return (notificationAction.errorNotification(response.data.error));
        }
        dispatch({type: loginConstants.FORGOT_SUCCESS});
        return (notificationAction.successNotification(`Nu har ett mail skickats till ${response.data.mailedTo}`));
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const onRegisterMember = (value) => {
    const registerSuccess = (user, userId, token) => ({
        type: loginConstants.REGISTER_SUCCESS,
        user,
        userId,
        token,
    });

    const promise = api.protectedFetch(`${constants.ROOT_URL}user`, [], 'POST', {}, value)
    const apiInit = () => ({ type: loginConstants.REGISTER_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 201) {
            dispatch({ type: loginConstants.REGISTER_FAILURE})
            return (notificationAction.errorNotification(response.data.error));
        }
        dispatch(registerSuccess(response.data.user, response.data.userId, response.data.token));
        return (notificationAction.successNotification(`Välkommen som medlem`));
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const onLogout = () => {      
    return ({ type: loginConstants.LOGOUT });
 
  };

const onCollapse= (collapsed) => {   
      if(collapsed){ 
        return ({ type: loginConstants.MOBILE });
      }else{
        return ({ type: loginConstants.DESKTOP });
      }
 
  };

const onForgotDone = () => {      
    return ({ type: loginConstants.FORGOT_DONE });
  };

const onForgotSuccess = () => {      
    return ({ type: loginConstants.FORGOT_SUCCESS2 });
 
  };

const loginActions = {
    onLogin,
    onLogout,
    onCollapse,
    onForgotPassword,
    onForgotDone,
    onForgotSuccess,
    onRegisterMember,
    // doRefresh,
};

export default loginActions;