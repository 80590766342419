import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import loginAction from '../SignIn/action';
import {
    Button, Popover
} from 'antd';

import ChangeUser from './ChangeUser';
import userActions from './action';


class UserPopup extends Component {

    state = {
        forgotVisible: false,
        registerVisible: false,
        popupVisible: false,
    }


    submitNewPassword = (values) => {
        const { forgotPassword } = this.props;
        forgotPassword(values);
        this.setState({ forgotVisible: false })
    }

    updateMember = (values, id) => {
        const { update } = this.props;
        update(values, id);
        this.setState({ registerVisible: false })
    }

    render() {

        const handleRegisterCancel = () => {
            this.setState({ registerVisible: false });
        };

        const showRegister = () => {
            this.setState({ registerVisible: true });
            this.setState({ popupVisible: false });
        }

        const handleVisibleChange = (visible) => {
            this.setState({ popupVisible: visible });
        }

        const getContent = () => {

            return <div>
                <ChangeUser onCancel={handleRegisterCancel}
                    onUpdate={this.updateMember}
                    visible={this.state.registerVisible}
                    loading={this.props.inProgress} 
                    userId={this.props.userId}/>
                <Button type="link" onClick={showRegister}>Ändra</Button>
                <Button type="link" onClick={this.props.logout}>Logga ut</Button>

                            </div>
                
        }


        return (
            <Popover placement="bottomRight"
                title={this.props.user}
                content={getContent()}
                trigger="click"
                visible={this.state.popupVisible}
                onVisibleChange={handleVisibleChange}
            >
                {this.props.user}
            </Popover>

        );
    }
}

const mapStateToProps = state => ({
    ...state.login,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        logout: () => loginAction.onLogout(),

        update: (user, id) => {
            return userActions.onUpdateMember(user, id);
        },    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserPopup);