import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const RequireAuthenticationAndAdmin = ({ component: Component, ...props }) => (
  <div>
    {props.isAuthenticated === true && isAdmin(props.userId) ? (
      <Route {...props} component={Component} />
    ) : (
      <div>
        <h1>Inga rättighter att visa sidan</h1>
      </div>
    )}
  </div>
);

const isAdmin = userId => (userId === 1);

const mapStateToProps = state => ({
  token: state.login.token,
  isAuthenticated: state.login.isAuthenticated,
  userId: state.login.userId,
});

export default withRouter(connect(mapStateToProps)(RequireAuthenticationAndAdmin));
