import constant from '../constants';

const initialState = {
  inProgress: false, failed: false, error: null,
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case constant.API_REQUEST:
      return {
        ...initialState,
        inProgress: true,
        failed: false,
      };
    case constant.API_REQUEST_FAILURE:
      return {
        ...initialState,
        inProgress: false,
        failed: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export default api;
