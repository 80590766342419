const constants = {
  API_REQUEST: 'API_REQUEST',
  API_REQUEST_SUCCESS: 'API_REQUEST_SUCCESS',
  API_REQUEST_FAILURE: 'API_REQUEST_FAILURE',

  CONTAINER: 'CONTAINER',

  SUCCESS_NOTIFICATION: 'SUCCESS_NOTIFICATION',
  INFO_NOTIFICATION: 'INFO_NOTIFICATION',
  WARNING_NOTIFICATION: 'WARNING_NOTIFICATION',
  ERROR_NOTIFICATION: 'ERROR_NOTIFICATION',

  CLEAR_SUCCESS: 'CLEAR_SUCCESS',
  CLEAR_INFO: 'CLEAR_INFO',
  CLEAR_WARNING: 'CLEAR_WARNING',
  CLEAR_ERROR: 'CLEAR_ERROR',

  GET_PERMISSIONS: 'GET_PERMISSIONS',

  GET_PERMISSION_BY_NAME_INIT: 'GET_PERMISSION_BY_NAME_INIT',
  GET_PERMISSION_BY_NAME_SUCCESS: 'GET_PERMISSION_BY_NAME_SUCCESS',

  CHECK_IS_KUBERNETES_INIT: 'CHECK_IS_KUBERNETES_INIT',
  CHECK_IS_KUBERNETES_SUCCESS: 'CHECK_IS_KUBERNETES_SUCCESS',
  TOOGLE_SIDER: 'TOOGLE_SIDER',
  
  GET_ALL_REQUEST: 'GET_ALL_RECEPT',
  GET_ALL_SUCCESS: 'GET_ALL_SUCCESS',
  GET_ALL_FAILURE: 'GET_ALL_FAILURE',
  GET_ALL_DONE: 'GET_ALL_DONE',

  GET_RECEPT_REQUEST: 'GET_RECEPT_REQUEST',
  GET_RECEPT_SUCCESS: 'GET_RECEPT_SUCCESS',
  GET_RECEPT_FAILURE: 'GET_RECEPT_FAILURE',
  GET_RECEPT_DONE: 'GET_RECEPT_DONE',

  ROOT_URL: process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : 'https://receptback.henjos.se/'
};

export default constants;
