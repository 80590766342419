import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UserModal from './UserModal';
import userAction from './action';




class ChangeUser extends Component {
    
    componentDidUpdate(){
        if(this.props.visible && !this.props.user.inProgress && this.props.user.user.id === undefined){
            this.props.getUser(this.props.userId)
        }
      }

    updateMember = (values) => {
        const { onUpdate } = this.props;
        //Remove undeifiend values.
        onUpdate(JSON.parse(JSON.stringify(values)), this.props.user.user.id);
    }
    
    render() {
        return (
                <UserModal onCancel={this.props.onCancel}
                    onCreate={this.updateMember}
                    visible={this.props.visible}
                    loading={this.props.user.inProgress} 
                    user={this.props.user.user}
                    update={true}
                    />
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getUser: (userId) => {
            return userAction.onGetUser(userId);
        },
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChangeUser);