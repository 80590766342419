import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import { loadState, saveAuthState } from '../common/localStorage';


const persistedState = loadState();
const initialState = persistedState !== null ? persistedState : {};
const enhancers = [];

/* eslint no-undef: "off" */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const composedEnhancers = () => {
    //if (process.env.ENABLE_DEBUG === 'yes') {
      return composeEnhancers(
        applyMiddleware(thunk),
        ...enhancers,
       // DevTools.instrument(),
      );
    //}
    /* return compose(
      applyMiddleware(...middleware),
      ...enhancers,
    ); */
  };

const store = createStore(rootReducer, initialState, composedEnhancers());
store.subscribe(() => {
    saveAuthState({
      login: store.getState().login,
    });
  });

export default store;