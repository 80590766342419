import axios from 'axios';
import { loadAuthToken } from '../common/localStorage';
import constants from '../common/constants';


const defaultHeaders = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};

const mkUrl = (url, hosts) => {
  if (hosts.length > 0) {
    const { host, port } = hosts[Math.floor((Math.random() * hosts.length) + 1) - 1];
    return `http://${host}:${port}${url}`;
  }
  return url;
};

/* eslint no-undef: "off" */
const protectedFetch = (url, hosts = [], method = 'GET', additionalHeaders = {}, body = undefined, timeout = 60000) => {
  let headers;
  const authToken = loadAuthToken();
  if(authToken) {
    headers = { Authorization: `Bearer ${authToken}`, ...additionalHeaders };
  } else {
    headers = additionalHeaders;
  }

  return axios({
    url: mkUrl(url, hosts),
    method,
    headers: { ...defaultHeaders, ...headers },
    data: body,
    timeout,
  })
    .then(res => res)
    .catch(err => {
      console.log('ProtectedFetch err: ', err);
      if (err.response) {
        return (err.response);
      }
      return { status: 0, data: { ErrorMessage: err.message } };
    });
};

const login = (usr, pwd, hosts = []) => {

  const body = {
    username: usr,
    password: pwd,
  };

  //const headers = { 'Content-Type': 'application/json;charset=UTF-8' };

  return protectedFetch(`${constants.ROOT_URL}auth`, hosts, 'POST', {}, body)
    .then(resp => ({
      status: resp.status,
      token: resp.token,
      userName: usr,
      userId: resp.userId,
      data: resp.data,
    })).catch(res => res);
};

const refreshToken = oldToken => {
  const headers = { 'Content-Type': 'text/plain;charset=UTF-8' };

  return protectedFetch('/auth/refresh', [], 'POST', headers, oldToken)
    .then(resp => ({
      status: resp.status,
      token: resp.data.trim(),
    })).catch(res => res);
};

export default {
  login,
  protectedFetch,
  refreshToken,
};
