import loginConstant from './constants';

const initialState = {
    isAuthenticated: false, inProgress: false, failed: false, user: '', userId: null, token: null, mobile: false, newPassTo: '',
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case loginConstant.LOGIN_REQUEST:
            return {
                ...initialState,
                user: action.user,
                inProgress: true,
                mobile: state.mobile,
            };
        case loginConstant.FORGOT_REQUEST:
        case loginConstant.REGISTER_REQUEST:
            return {
                ...initialState,
                inProgress: true,
                mobile: state.mobile,
            };
        case loginConstant.LOGIN_SUCCESS:
        case loginConstant.REFRESH_SUCCESS:
        case loginConstant.REGISTER_SUCCESS:
            return {
                ...initialState,
                isAuthenticated: true,
                token: action.token,
                user: action.user,
                userId: action.userId,
                mobile: state.mobile,
            };
        case loginConstant.LOGOUT:
        case loginConstant.AUTH_FAILED:
            return {
                ...initialState,
                user: null,
                userId: null,
                token: null,
                mobile: state.mobile,
            };
        case loginConstant.MOBILE:
            return {
                ...state,
                mobile: true,
            };
        case loginConstant.DESKTOP:
            return {
                ...state,
                mobile: false,
            };
        case loginConstant.FORGOT_SUCCESS:
        case loginConstant.LOGIN_FAILURE:
        case loginConstant.FORGOT_FAILURE:
        case loginConstant.REGISTER_FAILURE:
            return {
                ...initialState,
                mobile: state.mobile,
            }
        case loginConstant.USER_UPDATED:
            return {
                ...initialState,
                isAuthenticated: true,
                token: state.token,
                user: action.user,
                userId: state.userId,
                mobile: state.mobile,
            };
        default:
            return state;
    }

}

export default login;