import React, { Component } from 'react';
import { Input } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import searchAction from './action';


class SearchInput extends Component {


    state = {
        searchValue: this.props.search.searchedTitel
    }

    onSearch = value => {
        this.props.onSearch(value)
        if (this.props.extraOnSearch) {
            this.props.extraOnSearch()
            this.setState({ searchValue: "" })
        }
    };

    onChange = value => {
        this.setState({ searchValue: value })
    };


    render() {
        return (
            <Input.Search placeholder="Sök på recepttitel"
                onSearch={this.onSearch}
                value={this.state.searchValue}
                onChange={e => this.onChange(e.target.value)}
                defaultValue={this.props.search.searchedTitel}
                enterButton />
        );
    }
}


const mapDispatchToProps = dispatch => bindActionCreators(
    {
        onSearch: (recepttitel) => {
            return searchAction.onSearch(recepttitel);
        },
    },
    dispatch,
);

const mapStateToProps = state => ({
    search: state.search,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SearchInput);