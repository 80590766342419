import constants from '../constants';

const initialState = {
  allRecept: [], allFetched: false, failed: false, recept: {}, receptFetched: false
};

const get = (state = {...initialState}, action) => {
    switch (action.type) {
        case constants.GET_ALL_REQUEST:
            return {
                ...state,
                };
        case constants.GET_ALL_SUCCESS:
            return {
                ...state,
                allRecept: action.allRecept,
                allFetched: true
                };
        case constants.GET_ALL_FAILURE:
            return {
                ...initialState,
                failed: true,
            };
        case constants.GET_RECEPT_REQUEST:
            return {
                ...state,
                };
        case constants.GET_RECEPT_SUCCESS:
            return {
                ...state,
                recept: action.recept,
                receptFetched: true
                };
        case constants.GET_RECEPT_FAILURE:
            return {
                ...initialState,
                failed: true,
            };
        default:
            return state;
    }

}

export default get;