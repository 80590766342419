import React, {useState, useEffect} from 'react';
import { Modal, Form, Input } from 'antd';

const ForgotPasswordForm = ({ visible, onCreate, onCancel, loading }) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(visible);
  const [sendValues, setSendValues] = useState(undefined);
  

  useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  useEffect(() => {
    if(sendValues !== undefined){
      onCreate(sendValues)
    }
  }, [sendValues, onCreate])

  
  return (
    <Modal
      visible={modalVisible}
      title="Begära nytt lösenord"
      okText="Skicka"
      cancelText="Avbryt"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            setSendValues(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ loading: loading }}
    >
      <Form name='forgotPassword'
        form={form}
      >
        <Form.Item
          name={['username']}
          label="Användarnamn"
          rules={[
            {
              required: true, message: 'Vänligen fyll i användarnamn'
            },
          ]}
        >
          <Input
            className="username-fld"
            //prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Fyll i användarnamn"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordForm;