const addReceptConstants = {
  ADD_RECEPT_CLEAN: 'ADD_RECEPT_CLEAN',
  ADD_RECEPT_REQUEST: 'ADD_RECEPT_REQUEST',
  ADD_RECEPT_SUCCESS: 'ADD_RECEPT_SUCCESS',
  ADD_RECEPT_FAILURE: 'ADD_RECEPT_FAILURE',
  ADD_RECEPT_DONE: 'ADD_RECEPT_DONE',

  GET_RECEPT_INIT: 'GET_RECEPT_INIT',
  GET_RECEPT_DONE: 'GET_RECEPT_DONE',
  GET_RECEPT_FAIL: 'GET_RECEPT_FAIL',

  EDIT_RECEPT_CLEAN: 'EDIT_RECEPT_CLEAN',
  EDIT_RECEPT_REQUEST: 'EDIT_RECEPT_REQUEST',
  EDIT_RECEPT_SUCCESS: 'EDIT_RECEPT_SUCCESS',
  EDIT_RECEPT_FAILURE: 'EDIT_RECEPT_FAILURE',
  EDIT_RECEPT_DONE: 'EDIT_RECEPT_DONE',

  LOAD_ADD_INIT: 'LOAD_ADD_INIT',
  LOAD_ADD_UNIT: 'LOAD_ADD_UNIT',
  LOAD_ADD_TAG: 'LOAD_ADD_TAG',
  LOAD_ADD_INGREDIENT: 'LOAD_ADD_INGREDIENT',
  LOAD_ADD_DONE: 'LOAD_ADD_DONE',
  LOAD_ADD_FAIL: 'LOAD_ADD_FAIL',

  DELETE_RECEPT_CLEAN: 'DELETE_RECEPT_CLEAN',
  DELETE_RECEPT_REQUEST: 'DELETE_RECEPT_REQUEST',
  DELETE_RECEPT_SUCCESS: 'DELETE_RECEPT_SUCCESS',
  DELETE_RECEPT_FAILURE: 'DELETE_RECEPT_FAILURE',
  DELETE_RECEPT_DONE: 'DELETE_RECEPT_DONE',
};

export default addReceptConstants;
