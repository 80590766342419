import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import loginAction from './action';
import {
    Form, Input, Button, Spin, Popover,
} from 'antd';
import ForgotPasswordForm from './ForgotPasswordForm.js';
import loginActions from './action';
import RegisterUser from '../User/RegisterUser';

const FormItem = Form.Item;

class SignInPopup extends Component {

    state = {
        forgotVisible: false,
        registerVisible: false,
        popupVisible: false,
    }

    submit = (values) => {
        const { login } = this.props;
        login(
            values.username,
            values.password,
            //this.props.location.search,
            this.props,
        );
    }

    submitNewPassword = (values) => {
        const { forgotPassword } = this.props;
        forgotPassword(values);
        this.setState({ forgotVisible: false })
    }

    submitNewMember = (values) => {
        const { register } = this.props;
        register(values);
        this.setState({ registerVisible: false })
    }

    render() {

        const handleForgotCancel = () => {
            this.setState({ forgotVisible: false });
        };

        const handleRegisterCancel = () => {
            this.setState({ registerVisible: false });
        };

        const showForgot = () => {
            this.setState({ forgotVisible: true });
            this.setState({ popupVisible: false });
        }

        const showRegister = () => {
            this.setState({ registerVisible: true });
            this.setState({ popupVisible: false });
        }

        const handleVisibleChange = (visible) => {
            this.setState({ popupVisible: visible });
        }

        const getContent = () => {

            return <Spin spinning={this.props.inProgress}>
                <ForgotPasswordForm onCancel={handleForgotCancel}
                    onCreate={this.submitNewPassword}
                    visible={this.state.forgotVisible}
                    loading={this.props.inProgress} />
                <RegisterUser onCancel={handleRegisterCancel}
                    onCreate={this.submitNewMember}
                    visible={this.state.registerVisible}
                    loading={this.props.inProgress} />

                <Form name='signin'
                    onFinish={this.submit}
                >
                    <FormItem
                        name={['username']}
                        label="Användarnamn"
                        rules={[
                            {
                                required: true, message: 'Vänligen fyll i användarnamn'
                            },
                        ]}
                    >
                        <Input
                            className="username-fld"
                            //prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Fyll i användarnamn"
                            autoComplete="username"
                        />
                    </FormItem>
                    <FormItem label="Lösenord"
                        name={['password']}

                        rules={[
                            {
                                required: true, message: 'Vänligen fyll i ditt lösenord!'
                            },
                        ]}
                    >
                        <Input.Password autoComplete="current-password" />
                    </FormItem>
                    <FormItem className="button-wrapper">
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            id="login-button"
                            loading={this.props.inProgress}
                        >
                            Logga in
                        </Button>
                        <Button
                            type="link"
                            onClick={showForgot}
                        >
                            Glömt lösenord?
                        </Button>
                        <Button
                            type="link"
                            onClick={showRegister}
                        >
                            Ej medlem!
                        </Button>
                    </FormItem>
                </Form>
            </Spin>
        }


        return (
            <Popover placement="bottomRight"
                title={this.props.login.user}
                content={getContent()}
                trigger="click"
                visible={this.state.popupVisible}
                onVisibleChange={handleVisibleChange}
            >
                Logga in
            </Popover>

        );
    }
}

const mapStateToProps = state => ({
    ...state.login,
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        //changePage: () => push('/about-us'),
        login: (usr, pass, props) => {
            const baseRedirect = '/';
            //const redirect = searchLocation ? searchLocation.replace('?next=', '') : baseRedirect;
            return loginAction.onLogin(usr, pass, baseRedirect, props);
        },

        forgotSuccess: () => {
            return loginAction.onForgotSuccess();
        },
        register: (values) => { return loginActions.onRegisterMember(values) }
    },
    dispatch,
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignInPopup);