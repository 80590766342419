import userConstants from './constants';
import api from '../../api/api';
import handleApiAction from '../../api/utils';
import constants from '../../common/constants';
import notificationAction from '../../common/actions/notification_action';
import loginConstants from '../SignIn/constants';



const onGetUser = (userId) => {
    const getSuccess = (user) => ({
        type: userConstants.GET_USER_SUCCESS,
        user
    });
    const promise = api.protectedFetch(`${constants.ROOT_URL}user/${userId}`)
    const apiInit = () => ({ type: userConstants.GET_USER_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 200) {
            dispatch({ type: userConstants.GET_USER_FAILURE})
            return (notificationAction.errorNotification(response.data.error));
        }
        return getSuccess(response.data.user);
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const onUpdateMember = (user, id) => {
    const registerSuccess = (user) => ({
        type: loginConstants.USER_UPDATED,
        user,
    });

    const promise = api.protectedFetch(`${constants.ROOT_URL}user/${id}`, [], 'PUT', {}, user)
    const apiInit = () => ({ type: userConstants.UPDATE_USER_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 200) {
            dispatch({ type: userConstants.UPDATE_USER_FAILURE})
            return (notificationAction.errorNotification(response.data.error));
        }
        dispatch(registerSuccess(response.data.user.name));
        return (notificationAction.successNotification(`Användardata updaterat`));
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const onRegisterMember = (value) => {
    const registerSuccess = (user, userId, token) => ({
        type: loginConstants.REGISTER_SUCCESS,
        user,
        userId,
        token,
    });

    const promise = api.protectedFetch(`${constants.ROOT_URL}user`, [], 'POST', {}, value)
    const apiInit = () => ({ type: loginConstants.REGISTER_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 201) {
            dispatch({ type: loginConstants.REGISTER_FAILURE})
            return (notificationAction.errorNotification(response.data.error));
        }
        dispatch(registerSuccess(response.data.user, response.data.userId, response.data.token));
        return (notificationAction.successNotification(`Välkommen som medlem`));
    };

    return handleApiAction(promise, onSuccess, apiInit);
};

const userActions = {
    onGetUser,
    onUpdateMember,
    onRegisterMember,
};

export default userActions;