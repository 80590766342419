const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_DONE: 'USERS_LOGIN_DONE',

  FORGOT_REQUEST: 'FORGOT_REQUEST',
  FORGOT_SUCCESS: 'FORGOT_SUCCESS',
  FORGOT_FAILURE: 'FORGOT_FAILURE',

  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  USER_UPDATED: 'USER_UPDATED',

  LOGOUT: 'USERS_LOGOUT',

  AUTH_FAILED: 'AUTH_FAILED',

  REFRESH_SUCCESS: 'TOKEN_REFRESH_SUCCESS',
  REFRESH_FAILURE: 'TOKEN_REFRESH_FAILURE',
  REFRESH_DONE: 'TOKEN_REFRESH_DONE',

  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP',
};

export default userConstants;
