import constants from '../constants';

const initialState = {
  success: '', info: '', warning: '', error: '',
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case constants.SUCCESS_NOTIFICATION:
      return {
        ...state,
        success: action.payload.message,
      };
    case constants.INFO_NOTIFICATION:
      return {
        ...state,
        info: action.payload.message,
      };
    case constants.WARNING_NOTIFICATION:
      return {
        ...state,
        warning: action.payload.message,
      };
    case constants.ERROR_NOTIFICATION:
      return {
        ...state,
        error: action.payload.message,
      };
    case constants.CLEAR_SUCCESS:
      return {
        ...state,
        success: '',
      };
    case constants.CLEAR_INFO:
      return {
        ...state,
        info: '',
      };
    case constants.CLEAR_WARNING:
      return {
        ...state,
        warning: '',
      };
    case constants.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };
    default:
      return state;
  }
};

export default notification;
