const addReceptConstants = {

  GET_LOGS_INIT: 'GET_LOGS_INIT',
  GET_LOGS_DONE: 'GET_LOGS_DONE',
  GET_LOGS_FAIL: 'GET_LOGS_FAIL',
  GET_RECEPT_STAT_INIT: 'GET_RECEPT_STAT_INIT',
  GET_RECEPT_STAT_DONE: 'GET_RECEPT_STAT_DONE',
  GET_RECEPT_STAT_FAIL: 'GET_RECEPT_STAT_FAIL',
  GET_USERS_INIT: 'GET_USERS_INIT',
  GET_USERS_DONE: 'GET_USERS_DONE',
  GET_USERS_FAIL: 'GET_USERS_FAIL',

};

export default addReceptConstants;
