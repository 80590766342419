import React, { lazy, Suspense } from 'react';
import { Skeleton } from 'antd';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '../../components/PrivateRoute';
import AdminRoute from '../../components/AdminRoute';


const ShowRecept = lazy(() => import('../ShowRecept/ShowRecept'));
const Start = lazy(() => import('../Start'));
const AddRecept = lazy(() => import('../AddRecept/AddRecept'));
const EditRecept = lazy(() => import('../AddRecept/EditRecept'));
const SignIn = lazy(() => import('../SignIn/SignIn'));
const Search = lazy(() => import('../Search/Search'));
const ShowLog = lazy(() => import('../Admin/ShowLog'));
const ShowStatistics = lazy(() => import('../Admin/ShowStatistics'));
const ShowUsers = lazy(() => import('../Admin/ShowUsers'));



const Navigation = () => (
    <Suspense fallback={<Skeleton />}>
        <Switch>
            <Route exact path="/showrecept/:id" component={ShowRecept} />
            <PrivateRoute path="/editrecept/:id" component={EditRecept} />
            <PrivateRoute path="/addrecept" component={AddRecept} />
            <AdminRoute path="/log" component={ShowLog} />
            <AdminRoute path="/statistics" component={ShowStatistics} />
            <AdminRoute path="/users" component={ShowUsers} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/" component={Start} />


        </Switch>
    </Suspense>
)


export default Navigation;