import userConstant from './constants';

const initialState = {
    inProgress: false, user: {},
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case userConstant.GET_USER_REQUEST:
            return {
                ...initialState,
                inProgress: true,
            };
        case userConstant.GET_USER_SUCCESS:
            return {
                ...initialState,
                user: action.user,
            };
        case userConstant.GET_USER_FAILURE:
            return {
                ...initialState,
            }
        default:
            return state;
    }

}

export default user;