import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Main from './containers/Main/Main';
import store from './config/store';

function App() {
  require('dotenv').config()

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/" component={Main} />
      </BrowserRouter>
    </Provider>
  );
}
//        <Start />
//      <DevTools />


export default App;
