/* eslint-env browser */
export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('authState');
    if (serializedState === null) {
      return null;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('authState', serializedState);
  } catch (err) {
    // eslint-disable-next-line
    console.error('Unable to save state in sessionStorage');
  }
};

export const saveAuthState = state => {
  const essentialState = {
    login: {
      ...state.login,
      inProgress: false,
      failed: false,
    },
  };
  saveState(essentialState);
};

export const removeState = () => {
  sessionStorage.removeItem('authState');
};

export const loadAuthToken = () => {
  const authState = loadState();
  if (authState) {
    return authState.login.token;
  }
  return null;
};

export const getCurrentUser = () => {
  const authState = loadState();
  if (authState) {
    return authState.login.userName;
  }
  return null;
};
