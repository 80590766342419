import searchConstants from './constants';
import api from '../../api/api';
import handleApiAction from '../../api/utils';
import constants from '../../common/constants';


const onSearch = (searchedTitel) => {
    return {
        type: searchConstants.SEARCH_START,
        searchedTitel,
    }
};

const onGetAll = () => {
    const getAllSuccess = (allRecept) => ({
        type: searchConstants.GET_ALL_SUCCESS,
        allRecept
    });
    const getAllFailure = error => ({ type: searchConstants.GET_ALL_FAILURE, errorMessage: error });

    const promise = api.protectedFetch(`${constants.ROOT_URL}recept`)

    const apiInit = () => ({ type: searchConstants.GET_ALL_REQUEST });

    const onSuccess = (response, dispatch) => {
        if (response.status !== 200) {
            return (getAllFailure(response.data.ErrorMessage));
        }
        dispatch(getAllSuccess(response.data));
        return { type: searchConstants.GET_ALL_DONE };
    };

    return handleApiAction(promise, onSuccess, apiInit);
};



const searchActions = {
    onSearch,
    onGetAll
};

export default searchActions;