import React, {useState, useEffect,} from 'react';
import { Modal, Form, Input, Spin } from 'antd';

const UserModal = ({ visible, onCreate, onCancel, loading, user, update = false }) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(visible);
  const [sendValues, setSendValues] = useState(undefined);

  
  useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  useEffect(() => {
    if(sendValues !== undefined){
      onCreate(sendValues)
    }
  }, [sendValues, onCreate])

  
  return (
    

    <Modal
      visible={modalVisible}
      title={update ? 'Ändra uppgifter': "Skapa konto"}
      okText={update ? 'Uppdatera': 'Skicka'}
      cancelText="Avbryt"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            setSendValues(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
      okButtonProps={{ loading: loading }}
    >
      <Spin spinning={update && user.id === undefined}>
      {console.log("User modal:",user )}
      {!update || user.id !== undefined ?
      <Form 
        form={form}
        initialValues={user}
      >
        <Form.Item
          name='username'
          label="Användarnamn"
          rules={[
            {
              required: true, message: 'Vänligen fyll i användarnamn'
            },
          ]}
        >
          <Input
            autoComplete="username"
            //prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Fyll i användarnamn"
          />
        </Form.Item>
        <Form.Item
          name={['name']}
          label="Visningsnamn"
          rules={[
            {
              required: true, message: 'Skriv in det namnet som du vill att andra ska se.'
            },
          ]}
        >
          <Input
            className="name-fld"
            //prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Fyll i visningsnamn"
          />
        </Form.Item>
        <Form.Item
          name={['email']}
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'Inte en giltig E-mail!',
            },
            {
              required: true, message: 'Vänligen fyll i mail address'
            },
          ]}
        >
          <Input
            type="email"
            //prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Fyll i mail address"
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item
          name={['password']}
          label="Lösenord"
          rules={[
            {
              required: !update, message: 'Vänligen fyll i lösenord'
            },
          ]}
        >
           <Input.Password autoComplete="new-password"/>
        </Form.Item>
        <Form.Item
          name={['verpassword']}
          label="Verifiera lösenord"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: !update, message: 'Vänligen fyll i samma lösenord igen'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Lösenorden är inte like!'));
              },
            }),
          ]}
        >
           <Input.Password autoComplete="new-password"/>
        </Form.Item>
      </Form> : ''}
      </Spin>
    </Modal>

  );
};

export default UserModal;