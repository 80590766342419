import searchConstants from './constants';

const initialState = {
    searchedTitel: "", allRecept: [], dataFetched: false, failed: false,
};

const search = (state = {...initialState}, action) => {
    switch (action.type) {
        case searchConstants.SEARCH_START:
            return {
                ...state,
                searchedTitel: action.searchedTitel
                };
        case searchConstants.GET_ALL_REQUEST:
            return {
                ...state,
                };
        case searchConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                allRecept: action.allRecept,
                dataFetched: true
                };
        case searchConstants.GET_ALL_FAILURE:
            return {
                ...initialState,
                failed: true,
            };
        default:
            return state;
    }

}

export default search;