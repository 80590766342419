import React, { Component } from 'react';
//import Layout from './hoc/Layout/Layout'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Layout, Row, Menu, Image, Col, notification } from 'antd';
import { UserOutlined, HomeOutlined, PlusSquareOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import receptLogo from './../../assets/images/titel.svg'
import Navigation from './Navigation';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import loginAction from '../SignIn/action';
import searchAction from '../Search/action';
import SearchInput from '../Search/SearchInput';
import notificationAction from '../../common/actions/notification_action';



import './Main.css';
import SignInPopup from '../SignIn/SignInPopup';
import UserPopup from '../User/UserPopup';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


class Main extends Component {

    componentDidUpdate(prevProps) {
        if (prevProps.notification !== this.props.notification) {
          if (
            prevProps.notification.success !== this.props.notification.success
            && this.props.notification.success !== ''
          ) {
            this.openNotificationWithIcon('success', 'Success', this.props.notification.success);
          }
          if (
            prevProps.notification.info !== this.props.notification.info
            && this.props.notification.info !== ''
          ) {
            this.openNotificationWithIcon('info', 'Info', this.props.notification.info);
          }
          if (
            prevProps.notification.warning !== this.props.notification.warning
            && this.props.notification.warning !== ''
          ) {
            this.openNotificationWithIcon('warning', 'Warning', this.props.notification.warning);
          }
          if (
            prevProps.notification.error !== this.props.notification.error
            && this.props.notification.error !== ''
          ) {
            this.openNotificationWithIcon('error', 'Error', this.props.notification.error);
          }
        }
      }

      openNotificationWithIcon = (type, title, text) => {
        const color = () => {
          if (type === 'info') {
            return '';
          } if (type === 'success') {
            return '#F8FEEF';
          } if (type === 'warning') {
            return '#FEFFE2';
          }
          return '#FDF1F0';
        };
    
        let modifiedText = '';
        if (text) {
          modifiedText = text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        } else {
          modifiedText = text;
        }
    
        const finalMessage = (
          <div>
            <div dangerouslySetInnerHTML={{ __html: modifiedText }} />
          </div>
        );
    
        notification[type]({
          message: title,
          className: 'notification-'.concat(`${type}`),
          description: finalMessage,
          onClose: () => this.props.clearNotifcation(type),
          style: {
            backgroundColor: color(), maxHeight: '500px', overflowWrap: 'break-word', overflowY: 'auto',
          },
        });
      };

    onSearch = value => {
        this.props.history.push("/search")
    };

    onCollapse = collapsed => {
        this.props.collapsed(collapsed);
    };


    render() {

        const { pathname } = this.props.location;
        const { mobile } = this.props.login;

        const getLogin = () => {
            if (!this.props.login.isAuthenticated) {
                return <Menu.Item key="/signin" icon={<UserOutlined />}>
                    <SignInPopup forgotPassword={this.props.forgotPassword} forgotDone={this.props.forgotDone}/>
                </Menu.Item>

            } else {
                return <Menu.Item key="/signin" icon={<UserOutlined />}>
                    <UserPopup />

                </Menu.Item>
            }
        }

        const getProtectedItems = () => {
            if (this.props.login.isAuthenticated) {
                return <Menu.Item key="/addrecept" icon={<PlusSquareOutlined />}>
                    <Link to="/addrecept">Lägg till recept</Link>
                </Menu.Item>
            }
        }

        const getAdminsItems = () => {
            if (this.props.login.isAuthenticated && this.props.login.userId === 1) {
                return <SubMenu
                key="sub1"
                //className={collapsed ? classes.submenu : ''}
                title={(
                  <span>
                     <span>{'Admin'}</span>
                  </span>
                  )}
                  icon={<SettingOutlined />}
              >
                <Menu.Item key="logs">
                    <Link to="/log">Loggar</Link>
                </Menu.Item>
                <Menu.Item key="statistcs">
                    <Link to="/statistics">Statistik</Link>
                </Menu.Item>
                <Menu.Item key="users">
                    <Link to="/users">Användare</Link>
                </Menu.Item>
                </SubMenu>
            }
        }

        const getSearchMenu = () => {
            if (mobile) {
                return <Menu.Item key="search" icon={<SearchOutlined />}>
                <Link to="/search">Sök</Link>
            </Menu.Item>
            }
        }

        return (
            <Layout hasSider="true">
                <Header id="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
                    <Row justify="space-between" wrap={false}>
                        <Col span={8}>
                            <div style={{ float: 'left' }} className="logot">
                                <Image alt="ReceptLogo" src={receptLogo} width={mobile ? 200 : 300} />
                            </div></Col>
                        <Col xl={4} l={1}></Col>
                        <Col xl={8}>
                            {
                                /*  <Menu theme="dark"
                                 mode="horisontal"
                                 defaultSelectedKeys={['/']}
                                 selectedKeys={[pathname]}
                                 style={{ display: 'flex', alignItems: 'center' }}
                                 >
                                 <Menu.Item key="/" icon={<HomeOutlined/>}>
                                     <Link to="/">Hem</Link>
                                 </Menu.Item>
                                 {getProtectedItems()}
                                 {getLogin()}
                             </Menu>  */
                            }

                        </Col>
                        <Col span={4}>
                            <div className="search" hidden={mobile}>
                                <SearchInput extraOnSearch={this.onSearch} />
                            </div></Col>
                    </Row>
                </Header>
                <Layout>
                    <Sider 
                    collapsed={mobile} 
                    onCollapse={this.onCollapse} 
                    breakpoint="md"
                    style={{ boxShadow: '#ddd 2px 0px 2px' }}
                    theme="dark"

                    >
                        <Menu theme="dark"
                            mode="inline"
                            defaultSelectedKeys={['/']}
                            selectedKeys={[pathname]}
                            style={{ marginTop: '65px' }}
                        >
                            <Menu.Item key="/" icon={<HomeOutlined />}>
                                <Link to="/">Hem</Link>
                            </Menu.Item>
                            {getProtectedItems()}
                            {getSearchMenu()}
                            {getLogin()}
                            {getAdminsItems()}
                        </Menu>
                    </Sider>
                    <Layout>

                        <Content className="site-layout" style={{ padding: '0 0px', marginTop: 44 }}>

                            <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
                                <Navigation />
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>Henrik Pålsson</Footer>
                    </Layout>
                </Layout>

            </Layout>
        );
    }
}

const mapStateToProps = state => ({
    login: state.login,
    notification: state.notification,
    request: state.api,
    search: state.search
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        logout: () => loginAction.onLogout(),
        onSearch: (titel) => searchAction.onSearch(titel),
        collapsed: (collapsed) => loginAction.onCollapse(collapsed),
        forgotPassword: (value) => {
            return loginAction.onForgotPassword(value);
        },
        forgotDone: () => {
            return loginAction.onForgotDone();
        },
        clearNotifcation: notificationAction.clearNotifcation,

    },
    dispatch,
);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Main));